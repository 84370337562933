<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex ml-3 mb-2 align-items-center">
          <div class="mr-auto">
            <ul class="nav nav-pills">
              <li class="nav-item">
                <router-link to="/data-item-penomoran" class="nav-link active"
                  >Item Penomoran
                </router-link>
              </li>
              <li
                class="nav-item"
                v-if="checkAccess('setting', 'cru_item_payroll')"
              >
                <router-link to="/data-item-penggajian" class="nav-link"
                  >Item Penggajian</router-link
                >
              </li>
              <li class="nav-item" v-if="checkAccess('setting', 'sync')">
                <router-link to="/data-item-synchronize" class="nav-link"
                  >Sinkronisasi
                </router-link>
              </li>

              <li class="nav-item" v-if="checkAccess('setting', 'sync')">
                <router-link to="/data-fee-marketing" class="nav-link"
                  >Fee Marketing
                </router-link>
              </li>
              <!-- <li class="nav-item">
                                <router-link to="/data-level-project" class="nav-link">Level Project
                                </router-link>
                            </li> -->
            </ul>
          </div>

          <form class="search mr-3" @submit.prevent="onSearch">
            <input
              type="text"
              v-model="search"
              @keyup="searchCheck"
              id="searchbox"
              placeholder="Cari..."
              name="search"
            />
            <button type="submit"><i class="fa fa-search"></i></button>
          </form>
          <button
            class="btn-blue mr-2"
            v-if="enableSync"
            type="button"
            @click="getSync"
            :style="{
              padding: '8px 12px!important',
              borderRadius: '5px!important',
              border: '1px solid #248cf0',
              fontSize: '13px',
            }"
          >
            <img src="/img/icon-sync.svg" alt="" /> Data Sync
          </button>

          <!-- <router-link to="/data-item-penomoran/tambah" class="btn-add">+ Tambah </router-link> -->
        </div>
        <div class="card-table">
          <div class="table-responsive">
            <table
              class="table table-bordered table-striped table-hover"
              id="tableDepartment"
            >
              <thead>
                <tr>
                  <th style="width: 15%">Nama</th>
                  <th style="width: 20%">Tipe Transaksi</th>
                  <!-- <th style="width: 20%">Departement</th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(value, index) in list"
                  :key="index"
                  v-on:click="detail(value.id)"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Klik untuk melihat detail"
                >
                  <td>{{ value.nama }}</td>
                  <td>{{ value.transaksi_tipe }}</td>
                  <!-- <td>{{ value.divisi }}</td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";
import {
  checkModuleAccess,
  cksClient,
  showAlert,
  showLoading,
  checkApp,
} from "../../../helper";
import { get_CompanySettingList } from "../../../actions/setting";
import { post_SyncTable } from "../../../actions/integrations";
// import TableLite from 'vue3-table-lite'

export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    // TableLite
  },

  data() {
    return {
      enableSync: checkApp("rawuh") ? true : false,
      checkAccess: checkModuleAccess,
      isSearch: false,
      search: "",
      list: "",
      id_company: cksClient().get("_account").id_company,
      id_divisi: cksClient().get("_account").id_divisi,
      dataTable: "",
    };
  },

  beforeUnmount() {
    if (this.dataTable) {
      this.dataTable.destroy();
      this.dataTable = "";
    }
  },

  methods: {
    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    detail(id) {
      $('[data-toggle="tooltip"]').tooltip("hide");
      this.$router.push({ name: "DetailNumberingItems", params: { id: id } });
    },
    searchCheck: function () {
      if (!this.search && this.isSearch) {
        this.isSearch = false;
        this.getList();
      }
    },
    onSearch: function () {
      if (this.search || this.isSearch) {
        this.isSearch = true;
        this.getList();
      }
      if (!this.search && this.isSearch) {
        this.isSearch = false;
      }
    },
    async getList() {
      await get_CompanySettingList(
        {
          id_company: this.id_company,
          id_divisi: this.id_divisi,
          search: this.search,
        },
        (res) => {
          this.list = res.list;
          if (this.dataTable) {
            this.dataTable.destroy();
          }
          setTimeout(() => {
            this.dataTable = $("#tableDepartment").DataTable({
              info: false,
              language: {
                emptyTable: "Tidak ada data",
              },
            });
            this.tooltip();
          }, 1000);
        }
      );
    },

    getSync() {
      var show = showLoading(this.$swal);
      post_SyncTable(
        {
          id_company: cksClient().get("_account").id_company,
          table: "numbering",
        },
        () => {
          this.getList();
          setTimeout(() => {
            show.close();
            showAlert(this.$swal, {
              title: "SUCCESS!",
              msg: "Data telah disinkronkan",
              confirmButtonText: "Tutup",
            });
          }, 500);
        },
        () => {
          show.close();
          showAlert(this.$swal, {
            title: "ERROR!",
            msg: `Terjadi kesalahan, data tidak dapat disinkronkan`,
            cancelButtonText: "Tutup",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.card-table {
  margin-top: 0px;
}
</style>
